export const blue1 = '#A0BDDA'
export const blue2 = '#1A8EfA'
export const blue3 = '#0771D0'
export const blue4 = '#0A3761'

export const orange1 = '#fec002'
export const orange2 = '#ff9f00'
export const orange3 = '#FF6600'
export const orange4 = '#e63b00'

export const pink1 = '#D25299'

export const whiteAlpha30 = 'rgba(255,255,255,0.3)'
export const orange3Alpha80 = 'rgba(255,102,0,0.8)'

export const white = '#ffffff'

export const dark1 = '#e0e0e0'
export const dark2 = '#bfbfbf'
export const dark3 = '#767676'
export const dark4 = '#262626'

export const SCOUT_DARK = '#333333'

export const HEADER_ACTIVE_COLOR_LIGHT = orange3Alpha80
export const HEADER_ACTIVE_COLOR_DARK = orange3Alpha80
export const HEADER_HOVER_COLOR_LIGHT = whiteAlpha30
export const HEADER_HOVER_COLOR_DARK = whiteAlpha30

export const TEXT_COLOR_LIGHT = '#707070'
export const TEXT_COLOR_DARK = '#707070'

export const TEXT_COLOR_WHITE = '#FFFFFF'
export const TEXT_COLOR_GREY = '#707070'

export const PAGE_BACKGROUND_LIGHT = '#fafafa'
export const PAGE_BACKGROUND_DARK = dark4

export const SCOUT_TEAL = '#00ffcf'

export const PRIMARY_COLOR_1 = white
export const PRIMARY_COLOR_2 = SCOUT_TEAL

export const JOIN_BUTTON_COLOR = SCOUT_TEAL
export const DELETE_BUTTON_COLOR = SCOUT_DARK
export const LEAVE_BUTTON_COLOR = SCOUT_DARK
export const RELOAD_BUTTON_COLOR = 'green'


